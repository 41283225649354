<template>
  <header class="d-flex justify-content-center justify-content-lg-center">
    <img :src="require('@/assets/mbd.png')" class="my-3 mx-5" width="186" height="auto" alt="" draggable="false">
    <b-navbar toggleable="lg" type="dark" class="d-none d-lg-flex justify-content-around w-100">
      <template v-for="page in pages">
        <b-navbar-brand :key="page.title" v-if="!page.link" @click="$router.push(page.title)" :rel="`${page.title} tag`"
          class="text-uppercase">{{ page.title }}</b-navbar-brand>
        <a v-else :href="page.link" :key="page.title" target="_blank" class="navbar-brand text-uppercase"
          rel="noopener noreferrer">{{ page.title }}</a>
      </template>
    </b-navbar>
  </header>
</template>

<script>
  export default {
    data() {
      return {
        pages: [{
          title: 'empresa'
        }, {
          title: 'sistemas',
          link: 'https://pdvleve.com.br/'
        // }, {
        //   title: 'planos'
        }, {
          title: 'suporte'
        }, {
          title: 'produtos'
        }]
      }
    }
  }
</script>

<style scoped>
  nav.navbar .navbar-brand {
    color: #333;
    cursor: pointer;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 1rem !important;
    letter-spacing: .08em;
    text-transform: uppercase;
    padding: 0.5rem;
    /* border: 1px solid #333; */
    border-radius: 0.25rem;
    transition: all 0.3s;
  }

  nav.navbar .navbar-brand:hover {
    text-decoration: underline;
    /* background: #e67e22; */
    /* color: #fff; */
  }
</style>