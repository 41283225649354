<template>
  <section>
    <main-header />
    <div class="row mx-0">
      <b-carousel id="carousel-home" class="px-0" :interval='8000' fade indicators no-wrap :no-touch="false"
        v-model="slide">
        <b-carousel-slide v-for="(b,i) in banner" :key="'banner-home-'+i">
          <template #img>
            <img :src="require(`@/assets/produtos/${b}`)" class="d-block img-fluid w-100" :draggable="false">
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <div class="row my-0 my-lg-4 mx-0">
      <div class="d-lg-flex align-items-center justify-content-around my-3 my-lg-0 mx-3 col-12 px-0">
        <h2 class="m-0">Controles Inteligentes</h2>
        <h4>Simplificando a gestão da sua empresa.</h4>
      </div>
    </div>
  </section>
</template>

<script>
  import mainHeader from './components/header.vue'
  export default {
    components: {
      mainHeader,
    },
    data() {
      return {
        slide: 0,
        banner: ['AR.fw.png', 'Combos.fw.png', 'Produtos03.fw.png', 'Produtos04.fw.png', 'Produtos05.fw.png']
      }
    }
  }
</script>

<style scoped>
  h2 {
    font-size: 32px;
    font-weight: 700;
  }

  h4 {
    line-height: 1.1875;
    font-size: 22px;
  }

  .well {
    padding-top: 15vh;
  }

  @media screen and (max-width: 992px) {
    h2 {
      padding-top: 0;
      font-size: 32px;
    }

    h4 {
      line-height: 1.1875;
      font-size: 18px;
    }
  }
</style>

<style>
  #carousel-home .carousel-item {
    height: 70vh;
  }

  #carousel-home ol li {
    width: 20px;
    height: 20px;
    margin: 0 0.5em;
    border: 1px solid #333;
    border-radius: 50%;
    background: #ffffff90;
  }

  #carousel-home ol li.active {
    background: #333;
    border: 1px solid #fff;
  }

  @media screen and (max-width: 992px) {
    #carousel-home .carousel-item {
      height: auto;
    }

    #carousel-home ol {
      margin: 0;
    }

    #carousel-home ol li {
      width: 15px;
      height: 15px;
    }
  }
</style>